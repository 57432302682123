import React from 'react';


const InternshipDetails: React.FC = () => {
    // Dummy data to be displayed
    const studentName = 'Vijay Nagarjun Savvasere';
    const collegeName = 'East West Institute of Technology';
 
    const degreeName = 'Bachelor of Technology';
    const branchDiscipline = 'Computer Science';
    const yearSemester = '4th Year, 7th Semester';
    const startDate = '24th October 2024';
    const endDate = '21t February 2025';
    const topicName = 'Artificial Intelligence in Machine Learning';

  

    return (
        <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">
            {/* Logo Section */}
            <div className="w-full max-w-4xl flex flex-wrap justify-center gap-4 mb-8">
                <img src="/image/iimstc.png" alt="Logo 1" className="h-24 w-auto max-sm:h-16" />
                <img src="/image/skillIndia.png" alt="Logo 2" className="h-24 w-auto max-sm:h-16" />
                <img src="/image/VTU_logo.png" alt="Logo 3" className="h-24 w-auto max-sm:h-16" />
                <img
                    src="https://www.ecindia.org/Fourth-comming-event/ECI-WB.png"
                    alt="Logo 4"
                    className="h-24 w-auto max-sm:h-16"
                />
            </div>

            {/* Content Section */}
            <div className="h-auto w-full flex items-center justify-center px-4 max-sm:px-2">
                <div className="bg-gray-200 shadow-lg rounded-lg p-6 w-full max-w-[75rem] text-center flex flex-col items-center justify-center">
                    <h1 className="text-3xl font-bold mb-4 text-gray-800 max-sm:text-2xl">
                        Internship Completion Details
                    </h1>
                    <p className="mb-4 text-lg font-medium text-gray-800 max-sm:text-base">
    This is to certify that <strong>{studentName}</strong>, who is currently pursuing a
    <strong> {degreeName}</strong> in <strong>{branchDiscipline}</strong> at
    <strong> {collegeName}</strong>, and is enrolled in <strong>{yearSemester}</strong>,
    is currently undergoing an International Internship with an industry partnered with the International Institute of Medical Science & Technology Council.
</p>

                    <p className="mb-4 text-lg font-medium text-gray-800 max-sm:text-base">
                        The internship, conducted from <strong>{startDate}</strong> to
                        <strong> {endDate}</strong>, focused on the topic: <br />
                        "<strong>{topicName}</strong>"
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InternshipDetails;
