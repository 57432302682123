import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import StudentFeedback from './StudentFeedback';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import DailyLogForm from './DailyLogForm';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import '../styles/FullCalendarComponent.css';
import saikiranPDF from '../img/saikiran.pdf';

interface Props {
  selected: User | null;
  dailyLogs: DailyLog[];
attendance: DailyLog[];  
}

interface User {
  id: number;
  name: string;
  email: string;
}

interface DailyLog {
  id: number;
  day: string;
  date: string;
  arrivalTime: string;
  departureTime: string;
  remarks?: string;
  department: string;
  finishedProduct: string;
  hodName: string;
  hodEmail: string;
  mainPoints: string;
}

const FacultyAttendanceComponent: React.FC<Props> = ({ selected, dailyLogs }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [events, setEvents] = useState<Array<any>>([]);
  const [existingLog, setExistingLog] = useState<any>(null);
  const [mode, setMode] = useState<'create' | 'edit' | 'view' | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [showAssessmentButtons, setShowAssessmentButtons] = useState(false);

  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const navigate = useNavigate();

useEffect(() => {
  if (selected) {
    setUserId(selected.id);
  }
}, [selected]);


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (userId !== null) {
          const response = await axios.get(`/api/daily-logs/${userId}`);
          const logs = response.data.dailyLogs;
          const eventLogs = logs.map((log: any) => ({
            title: 'P',
            start: log.date,
            allDay: true,
            color: getLogColor(log.date),
          }));
          setEvents(eventLogs);
        }
      } catch (error) {
        console.error('Error fetching logs:', error);
      }
    };

    if (userId !== null) {
      fetchEvents();
    }
  }, [userId]);

  useEffect(() => {
    const fetchExistingLog = async () => {
      if (selectedDate && userId !== null) {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        try {
          const response = await axios.get(`/api/daily-logs/${userId}/${formattedDate}`);
          setExistingLog(response.data.dailyLog || null);
        } catch (error) {
          console.error('Error fetching existing log:', error);
        }
      }
    };
    fetchExistingLog();
  }, [selectedDate, userId]);

  const handleDateClick = async (arg: any) => {
    const clickedDate = new Date(arg.dateStr);
    const formattedDate = clickedDate.toISOString().split('T')[0];

    try {
      const response = await axios.get(`/api/daily-logs/${userId?.toString() || ''}/${formattedDate}`);
      const logData = response.data.dailyLog;

      setExistingLog(logData || null);
      setSelectedDate(clickedDate);

      // Set mode to 'view' if there's an existing log, otherwise to 'create'
      setMode(logData ? 'view' : 'create');
    } catch (error) {
      console.error('Error fetching existing log:', error);
      // If there's no log, set mode to 'create'
      setMode('create');
      setSelectedDate(clickedDate);
    }
  };

    const handleLogSubmit = async (log: any) => {
        try {
            const requestData = {
                ...log,
                userId: userId,
                date: selectedDate?.toISOString().split('T')[0],
            };

            if (existingLog) {
                await axios.put(`/api/daily-logs/daily-log/${userId}/${selectedDate?.toISOString().split('T')[0]}`, requestData);
            } else {
                await axios.post('/api/daily-logs/user', requestData);
            }

            setEvents((prevEvents) => {
                const formattedDate = requestData.date;
                const isDatePresent = prevEvents.some((event) => event.start === formattedDate);

                if (isDatePresent) {
                    return prevEvents.map((event) =>
                        event.start === formattedDate ? { ...event, title: 'P', color: getLogColor(formattedDate) } : event
                    );
                } else {
                    return [
                        ...prevEvents,
                        {
                            title: 'P',
                            start: formattedDate,
                            allDay: true,
                            color: getLogColor(formattedDate),
                        },
                    ];
                }
            });

            setSelectedDate(null);
            setExistingLog(null);
            setMode(null);
        } catch (error) {
            console.error('Error submitting log:', error);
        }
    };

    const handleLogDelete = async () => {
        if (selectedDate && userId) {
            try {
                const formattedDate = selectedDate.toISOString().split('T')[0];
                await axios.delete(`/api/daily-logs/${userId}/${formattedDate}`);

                setEvents((prevEvents) => prevEvents.filter((event) => event.start !== formattedDate));

                setSelectedDate(null);
                setExistingLog(null);
                setMode(null);
            } catch (error) {
                console.error('Error deleting log:', error);
            }
        }
    };

   

    const getLogColor = (date: string) => {
        const today = new Date();

        // Only highlight today's date
        if (new Date(date).toDateString() === today.toDateString()) {
            return 'cell-today';
        } else {
            return ''; // No special color for other dates
        }
    };

    const dayCellDidMount = (info: any) => {
        const cellDate = new Date(info.date);
        const today = new Date();

        // Only add the 'today' class for the current date
        if (cellDate.toDateString() === today.toDateString()) {
            info.el.classList.add('today');
        }
    };

    return (
        <div className="relative max-w-4xl mx-auto p-5 bg-gray-800 text-white rounded-lg shadow-lg">
            <h2 className="text-2xl mb-4">Daily Log Calendar</h2>
          <FullCalendar
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
  initialView="dayGridMonth"
  editable={true}
  selectable={true}
  selectMirror={true}
  dayMaxEvents={false} // Ensure all events are displayed without "+more"
  weekends={true}
  dateClick={handleDateClick}
  events={events}
  eventContent={(eventInfo) => (
    <div className="flex items-center justify-center h-full">
      <span className="text-center text-xs font-bold">{eventInfo.event.title}</span>
    </div>
  )}
  dayCellDidMount={dayCellDidMount}
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
  }}
/>


          


        </div>
    );
};

export default FacultyAttendanceComponent;
