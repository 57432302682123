import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/otp.css'; // Ensure your CSS file is named appropriately and located correctly

const OtpForm: React.FC = () => {
  // State for storing OTP input
  const [formData, setFormData] = useState({ otp: '' });
  const [message, setMessage] = useState(''); // State to show response message

  const navigate = useNavigate(); // Move useNavigate to the top level

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const email = localStorage.getItem('userEmail');
      
      const response = await fetch('/api/users/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, otp: formData.otp }),
      });

      const data = await response.json();
      setMessage(data.message); // Display the response message

      // Check if the response is successful based on status code (200-299)
      if (response.ok) {
        navigate('/student-login'); // Navigate to '/log' after successful OTP verification
      } else {
        setMessage('Failed to verify OTP. Please try again.');
      }
    } catch (error) {
      setMessage('Failed to verify OTP. Please try again.');
      console.error('Error during OTP verification:', error);
    }
  };

  const handleGoBack = (): void => {
    window.location.href = '../index.html';
  };

  return (
    <div className="otp-container">
      <form id="otpForm" className="otp-form" onSubmit={handleSubmit}>
        {/* OTP Section with Logo */}
        <fieldset className="otp-form-section">
          <legend className="otp-form-legend">
            <span className="otp-text"><strong>ENTER OTP</strong></span>
          </legend>

          <div className="otp-form-group">
            <label htmlFor="otp" className="otp-form-label">OTP</label>
            <input 
              id="otp" 
              name="otp" 
              type="text" 
              placeholder="Enter OTP" 
              className="otp-form-input" 
              value={formData.otp}
              onChange={handleChange} 
              required 
            />
          </div>
        </fieldset>

        <div className="otp-form-btn-container">
          <button type="submit" className="otp-form-btn otp-form-btn-submit">Submit</button>
          <button type="button" className="otp-form-btn otp-form-btn-go-back" onClick={handleGoBack}>Go Back</button>
        </div>

        {message && <p className="otp-message">{message}</p>}

        <div className="otp-additional-links">
          <p>Didn't receive an OTP? <a href="#resend" className="otp-link">Resend OTP</a></p>
          <img src="./image/log.webp" alt="Logo" className="otp-legend-logo" />
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
