import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Cropper from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import '../styles/signUpload.css';

const SignatureBackgroundRemover: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [processedImage, setProcessedImage] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        setImageSrc(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels);
  };

  const removeBackground = async () => {
    if (!imageSrc || !canvasRef.current) return;

    setIsLoading(true);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const image = new Image();
    image.src = imageSrc;
    image.onload = async () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // Enhance contrast and remove background
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

        if (brightness < 128) {
          data[i] = 0;
          data[i + 1] = 0;
          data[i + 2] = 0;
          data[i + 3] = 255;
        } else {
          data[i + 3] = 0;
        }
      }

      ctx.putImageData(imageData, 0, 0);

      setProcessedImage(canvas.toDataURL("image/png"));
      setIsLoading(false);
    };
  };

  const cropImage = () => {
    if (!processedImage || !croppedArea) return;

    const image = new Image();
    image.src = processedImage;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const { width, height, x, y } = croppedArea;

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(
        image,
        x,
        y,
        width,
        height,
        0,
        0,
        width,
        height
      );

      const croppedDataUrl = canvas.toDataURL("image/png");
      setProcessedImage(croppedDataUrl);
    };
  };
 useEffect(() => {
  const fetchUserId = async () => {
    try {
      const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
      if (IIMSTC_ID) {
        const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
        setUserId(response.data.userId);
       
        // Log the internship status to the console
       
      }
    } catch (error) {
      console.error('Error fetching user ID', error);
    }
  };

  fetchUserId();
}, []);
 const submitSignature = async () => {
  if (!processedImage) {
    alert("No signature image to submit. Please process the image first.");
    return;
  }

  try {
    // Indicate loading
    setIsLoading(true);

    // Convert the base64 processed image to a Blob
    const blob = await (await fetch(processedImage)).blob();
    const formData = new FormData();
    formData.append("signature", blob, "signature.png");

    // Assuming `userId` is fetched elsewhere or passed as a prop
    const response = await fetch(`/api/users/signature/${userId}`, {
      method: "POST",
      body: formData,
    });

    const result = await response.json();

    if (response.ok) {
      alert("Signature uploaded successfully!"); // Success alert
    } else {
      alert(`Failed to upload signature: ${result.message || "Unknown error"}`);
    }
  } catch (error) {
    console.error("Error uploading signature:", error);
    alert("An error occurred while uploading the signature. Please try again.");
  } finally {
    setIsLoading(false);
  }
};



  return (
    <div className="signature-container">
      <h1 className="signature-header"> Upload Image below </h1>
      <h2 className="signature-upload-header">Note:- "Upload a signature image with a plain white background and use the 'Remove Background' option to eliminate the background. If there are any dark black shades in the corners, use the crop tool to adjust by zooming in or out and dragging left or right. Ensure the signature fits perfectly within the white workspace, then click the 'Crop' button and submit the image."</h2>
      <div className="signature-extraction-container">
        {/* Upload Section */}

        <div className="signature-upload-section">

          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="signature-upload-input"
          />
          {imageSrc && (
            <div className="signature-upload-image-container">
              <img
                src={imageSrc}
                alt="Uploaded"
                className="signature-upload-image"
              />
            </div>
          )}
          <canvas ref={canvasRef} className="signature-canvas"></canvas>
          <div className="signature-button-container">
            <button
              onClick={removeBackground}
              disabled={isLoading}
              className="signature-button"
            >
              {isLoading ? "Processing..." : "Remove Background"}
            </button>
          </div>
        </div>

        {/* Processed Image Section */}
        {processedImage && (
          <div className="signature-processed-image-section">
            <h2 className="signature-crop-header">Crop Processed Signature</h2>
            <div className="signature-cropper-container">
              <Cropper
                image={processedImage}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="signature-button-container">
              <button onClick={cropImage} className="signature-button">
                Apply Crop
              </button>
              <button onClick={submitSignature} className="signature-button signature-download-button">
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignatureBackgroundRemover;
